import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { DeleteNoteInAPI } from "../utils/api";
import { Route, Link } from "react-router-dom";
import ItemDetail from "./detail";
import { GraphqlHOC } from "./graphqlHOC";
import {
  initializeList,
  setLoadAgainState,
  LOAD_AGAIN,
  // LOAD_COMPLETE,
  RemoveFromList,
} from "../core/actions";
import Auth from "@aws-amplify/auth";

const ListingComponent = ({
  portfolioItemsFromRedux,
  setLoadAgainStateNow,
  deleteFromListNow,
}) => {
  const [notes, setNotes] = useState([]);
  const [loadingState, setLoadingState] = useState(LOAD_AGAIN);
  const [username, setUsername] = useState("");

  useEffect(() => {
    // console.log("portfolioItemsFromRedux:", portfolioItemsFromRedux);

    if (Auth && Auth.user && Auth.user.username) {
      // console.log("auth useEffect with Auth:", Auth);
      setUsername(Auth.user.username);
    }
    setNotes(portfolioItemsFromRedux.itemsList);
    setLoadingState(portfolioItemsFromRedux.dataState);
  }, [notes, loadingState, portfolioItemsFromRedux]);

  const deleteNote = async (id) => {
    DeleteNoteInAPI(id)
      .then((resp) => {
        setLoadAgainStateNow();
        const newNotesArray = notes.filter((note) => note.id !== id);
        deleteFromListNow(id);
        setNotes(newNotesArray);
      })
      .catch((err) => {
        // console.log("failed to delete a row ... err:", err);
      });
  };

  return (
    <>
      {notes && notes.length > 0 ? (
        <div className="portfolioList">
          {notes
            .sort((a, b) => b.year.toString().localeCompare(a.year.toString()))
            .map((note, idx) => (
              <div
                key={`note${idx}` || note.name}
                className="portfolioListItem"
              >
                <div>
                  <span className="heading alignLeft">{note.name}</span>
                  <span className="smallCase alignRightNow">{note.year}</span>
                </div>
                <div>
                  <span className="alignLeft">{note.blurb}</span>
                </div>
                <div>
                  <span className="alignLeft">
                    <button
                      role="link"
                      onClick={() => {
                        window.location.href = `http://${note.url}`;
                      }}
                    >
                      click here to visit
                    </button>
                  </span>
                  <span className="alignRightNow">
                    <Link to={`/detail/${note.id}`}>...details</Link>
                  </span>
                </div>

                <Route path={`/detail/${note.id}`}>
                  <ItemDetail />
                </Route>
                {username && username !== "" ? (
                  <button onClick={() => deleteNote(note.id)}>
                    Delete note
                  </button>
                ) : null}
              </div>
            ))}
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  portfolioItemsFromRedux: state,
});

const mapDispatchToProps = (dispatch) => ({
  initializeTheList: (passedArray) => dispatch(initializeList(passedArray)),
  setLoadAgainStateNow: () => dispatch(setLoadAgainState()),
  deleteFromListNow: (passedId) => dispatch(RemoveFromList(passedId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GraphqlHOC(ListingComponent));
