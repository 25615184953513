import { combineReducers } from "redux";
import {
  ADD_TODO,
  TOGGLE_TODO,
  SET_VISIBILITY_FILTER,
  VisibilityFilters,
  INITIALIZE,
  ADD_ITEM,
  REMOVE_ITEM,
  LOAD_AGAIN,
  LOAD_COMPLETE,
} from "./actions";
const { SHOW_ALL } = VisibilityFilters;

function dataState(state = LOAD_AGAIN, action) {
  switch (action.type) {
    case LOAD_AGAIN:
      return LOAD_AGAIN;
    case LOAD_COMPLETE:
      return LOAD_COMPLETE;
    default:
      return state;
  }
}

function visibilityFilter(state = SHOW_ALL, action) {
  switch (action.type) {
    case SET_VISIBILITY_FILTER:
      return action.filter;
    default:
      return state;
  }
}

function itemsList(state = [], action) {
  // console.log("inside itemsList with action:", action.payload);
  switch (action.type) {
    case INITIALIZE:
      const newState = [...action.payload];
      return newState;
    case ADD_ITEM:
      const newState2 = [...state, action.payload];
      return newState2;

    case REMOVE_ITEM:
      let newState3 = [...state];
      newState3 = newState3.filter((x) => x.id !== action.payload);
      return newState3;
    default:
      return state;
  }
}

function todos(state = [], action) {
  switch (action.type) {
    case ADD_TODO:
      return [
        ...state,
        {
          text: action.text,
          completed: false,
        },
      ];
    case TOGGLE_TODO:
      return state.map((todo, index) => {
        if (index === action.index) {
          return Object.assign({}, todo, {
            completed: !todo.completed,
          });
        }
        return todo;
      });
    default:
      return state;
  }
}

const todoApp = combineReducers({
  dataState,
  visibilityFilter,
  todos,
  itemsList,
});

export default todoApp;
