/*
 * action types
 */
export const ADD_TODO = "ADD_TODO";
export const TOGGLE_TODO = "TOGGLE_TODO";
export const SET_VISIBILITY_FILTER = "SET_VISIBILITY_FILTER";

export const INITIALIZE = "INITIALIZE";
export const ADD_ITEM = "ADD_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";

export const LOAD_COMPLETE = "LOAD_COMPLETE";
export const LOAD_AGAIN = "LOAD_AGAIN";

/*
 * other constants
 */

export const VisibilityFilters = {
  SHOW_ALL: "SHOW_ALL",
  SHOW_COMPLETED: "SHOW_COMPLETED",
  SHOW_ACTIVE: "SHOW_ACTIVE",
};

/*
 * action creators
 */

export function addTodo(text) {
  return { type: ADD_TODO, text };
}

export function toggleTodo(index) {
  return { type: TOGGLE_TODO, index };
}

export function setVisibilityFilter(filter) {
  return { type: SET_VISIBILITY_FILTER, filter };
}

export function initializeList(itemsArray) {
  return { type: INITIALIZE, payload: itemsArray };
}

export function addToList(itemObj) {
  return { type: ADD_ITEM, payload: itemObj };
}

export function RemoveFromList(itemObjId) {
  return { type: REMOVE_ITEM, payload: itemObjId };
}

export function setLoadCompleteState() {
  return { type: LOAD_COMPLETE, payload: "" };
}

export function setLoadAgainState() {
  return { type: LOAD_AGAIN, payload: "" };
}
