import React, { useState } from "react";
import { connect } from "react-redux";
import { CreateNoteInAPI } from "../utils/api";
import "../styles/components.css";
import { setLoadAgainState } from "../core/actions";

const initialFormState = {
  name: "",
  blurb: "",
  description: "",
  url: "",
  year: "",
  source: "",
};

const DataEntryForm = ({ setLoadAgainStateNow }) => {
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState(initialFormState);

  const changeHandler = (e, fieldName) => {
    let updatedForm = { ...formData };
    updatedForm[fieldName] = e.target.value;
    setFormData(updatedForm);
  };

  const createNote = async () => {
    if (!formData.name || !formData.description) return;
    CreateNoteInAPI(formData)
      .then((createResponse) => {
        // setNotes([...notes, formData]);
        // updateNotes([...notes, formData]);
        // ResetStoreData();
        setFormData(initialFormState);
        setLoadAgainStateNow();
      })
      .catch((err) => {
        // console.log("then with err:", err);
        let createErrors = [...errors];
        err.errors.forEach((individualError) => {
          createErrors = [...createErrors, individualError.message];
        });
        setErrors(createErrors);
      });
  };

  return (
    <>
      <h1>My portfolio App</h1>
      <label htmlFor="pItemHeading">Portfolio Item heading</label>
      <input
        id="pItemHeading"
        onChange={(e) => changeHandler(e, "name")}
        placeholder="portfolio Item name"
        value={formData.name}
      />
      <br />
      <label htmlFor="pItemBlurb">Portfolio Item blurb</label>
      <input
        id="pItemBlurb"
        onChange={(e) => changeHandler(e, "blurb")}
        placeholder="blurb"
        value={formData.blurb}
      />
      <br />
      <label htmlFor="pItemDescr">Portfolio Item description</label>
      <input
        id="pItemDescr"
        onChange={(e) => changeHandler(e, "description")}
        placeholder="brief description"
        value={formData.description}
      />
      <br />
      <label htmlFor="pItemURL">Portfolio Item URL</label>
      <input
        id="pItemURL"
        onChange={(e) => changeHandler(e, "url")}
        placeholder="item URL"
        value={formData.url}
      />
      <br />
      <label htmlFor="pItemYear">Portfolio Item Year</label>
      <input
        id="pItemYear"
        onChange={(e) => changeHandler(e, "year")}
        type="number"
        placeholder="item URL"
        value={formData.year}
      />
      <br />
      <label htmlFor="pItemsource">Portfolio Item source</label>
      <input
        id="pItemsource"
        onChange={(e) => changeHandler(e, "source")}
        placeholder="item source?"
        value={formData.source}
      />
      <br />
      {errors && errors.length > 0 ? (
        <ul>
          {errors.map((err, indx) => (
            <li key={`li${indx}`} id={`li${indx}`} className="errorMessage">
              {err}
            </li>
          ))}
        </ul>
      ) : null}
      <button onClick={createNote}>Create Item</button>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLoadAgainStateNow: () => dispatch(setLoadAgainState()),
});

export default connect(null, mapDispatchToProps)(DataEntryForm);
