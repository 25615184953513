/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPortfolioItem = /* GraphQL */ `
  mutation CreatePortfolioItem(
    $input: CreatePortfolioItemInput!
    $condition: ModelportfolioItemConditionInput
  ) {
    createPortfolioItem(input: $input, condition: $condition) {
      id
      name
      blurb
      description
      url
      year
      source
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePortfolioItem = /* GraphQL */ `
  mutation UpdatePortfolioItem(
    $input: UpdatePortfolioItemInput!
    $condition: ModelportfolioItemConditionInput
  ) {
    updatePortfolioItem(input: $input, condition: $condition) {
      id
      name
      blurb
      description
      url
      year
      source
      tags {
        items {
          tagID {
            label
            name
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePortfolioItem = /* GraphQL */ `
  mutation DeletePortfolioItem(
    $input: DeletePortfolioItemInput!
    $condition: ModelportfolioItemConditionInput
  ) {
    deletePortfolioItem(input: $input, condition: $condition) {
      id
      name
      blurb
      description
      url
      year
      source
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPortfolioTagConnection = /* GraphQL */ `
  mutation CreatePortfolioTagConnection(
    $input: CreatePortfolioTagConnectionInput!
    $condition: ModelportfolioTagConnectionConditionInput
  ) {
    createPortfolioTagConnection(input: $input, condition: $condition) {
      id
      portfolioID {
        id
        name
        blurb
        description
        url
        year
        source
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tagID {
        id
        label
        name
        parentItem {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePortfolioTagConnection = /* GraphQL */ `
  mutation UpdatePortfolioTagConnection(
    $input: UpdatePortfolioTagConnectionInput!
    $condition: ModelportfolioTagConnectionConditionInput
  ) {
    updatePortfolioTagConnection(input: $input, condition: $condition) {
      id
      portfolioID {
        id
        name
        blurb
        description
        url
        year
        source
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tagID {
        id
        label
        name
        parentItem {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePortfolioTagConnection = /* GraphQL */ `
  mutation DeletePortfolioTagConnection(
    $input: DeletePortfolioTagConnectionInput!
    $condition: ModelportfolioTagConnectionConditionInput
  ) {
    deletePortfolioTagConnection(input: $input, condition: $condition) {
      id
      portfolioID {
        id
        name
        blurb
        description
        url
        year
        source
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tagID {
        id
        label
        name
        parentItem {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTechnologyTag = /* GraphQL */ `
  mutation CreateTechnologyTag(
    $input: CreateTechnologyTagInput!
    $condition: ModelTechnologyTagConditionInput
  ) {
    createTechnologyTag(input: $input, condition: $condition) {
      id
      label
      name
      parentItem {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTechnologyTag = /* GraphQL */ `
  mutation UpdateTechnologyTag(
    $input: UpdateTechnologyTagInput!
    $condition: ModelTechnologyTagConditionInput
  ) {
    updateTechnologyTag(input: $input, condition: $condition) {
      id
      label
      name
      parentItem {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTechnologyTag = /* GraphQL */ `
  mutation DeleteTechnologyTag(
    $input: DeleteTechnologyTagInput!
    $condition: ModelTechnologyTagConditionInput
  ) {
    deleteTechnologyTag(input: $input, condition: $condition) {
      id
      label
      name
      parentItem {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
