export const mockNotes = [
  {
    id: "weatherzone-2020",
    name: "weatherzone",
    blurb: "Weatherzone's public site on NextJS, storybook",
    description:
      "Developed key modules including News, synoptic, Ads integration etc on Weatherzone's upcoming public site built on NextJS, storybook, styled components",
    url: "beta.weatherzone.com.au/",
    year: 2020,
    source: "commercial",
    tags: {
      items: [
        { tagID: { name: "nextJS", label: "nextJS" } },
        { tagID: { name: "storybook", label: "storybook" } },
        { tagID: { name: "styled components", label: "styled-components" } },
      ],
    },
  },
  {
    id: "westpac-2020",
    name: "Westpac Open banking",
    blurb:
      "Developed back-end and front-end endpoints for westpac's open banking project",
    description:
      "Developed endpoint on NodeJS and React for westpac's open banking project; wrote a lot of jest unit tests to fulfill the high test coverage requirements",
    url: "www.westpac.com.au/about-westpac/innovation/open-banking/",
    year: 2020,
    source: "commercial",
    tags: {
      items: [
        { tagID: { name: "react", label: "react" } },
        { tagID: { name: "node", label: "node" } },
        { tagID: { name: "jest", label: "jest" } },
        { tagID: { name: "styled components", label: "styled-components" } },
      ],
    },
  },
  {
    id: "qbe-2020",
    name: "QBE Motor/Home Insurance",
    blurb:
      "Key application for the personal line of business for QBE with strong reliance on API for questions, get quote as a response",
    description:
      "Part of a small team which developed screens, reusable components for the motor insurance products. Using react, redux, redux saga, material components & styled components for a custom branding",
    url: "www.qbe.com/au/portal/quote/vehicle#/welcome",
    year: "2020",
    source: "commercial",
    tags: {
      items: [
        { tagID: { name: "react", label: "react" } },
        { tagID: { name: "redux", label: "redux" } },
        { tagID: { name: "Material UI", label: "materialUI" } },
        { tagID: { name: "styled component", label: "styled component" } },
        { tagID: { name: "redux saga", label: "redux-saga" } },
      ],
    },
  },
  {
    id: "ai-details-2020",
    name: "Akber Iqbal | Career",
    blurb:
      "Redux, routing used to track a user journey across the pages which highlight a different area of my profile",
    description:
      "Redux, routing used to track a user journey across the pages which highlight a different area of my profile",
    url: "www.akberiqbal.com/detailsR",
    year: "2020",
    source: "personal",
    tags: {
      items: [
        { tagID: { name: "react", label: "react" } },
        { tagID: { name: "redux", label: "redux" } },
        { tagID: { name: "Bootstrap 4", label: "bootstrap-4" } },
      ],
    },
  },
  {
    id: "choosi-2019",
    name: "Choosi",
    blurb: "Some reusable components on the banner, SEO changes implemented",
    description:
      "Some reusable components on the banner, SEO changes implemented",
    url: "www.choosi.com.au/",
    year: "2019",
    source: "commercial",
    tags: {
      items: [
        { tagID: { name: "jQuery", label: "jQuery" } },
        { tagID: { name: "CSS", label: "CSS" } },
      ],
    },
  },
  {
    id: "RSPCA-2019",
    name: "RSPCA Pet Insurance",
    blurb: "Developed custom layouts for new landing and detail pages",
    description: "Developed custom layouts for new landing and detail pages",
    url: "www.rspcapetinsurance.org.au",
    year: "2019",
    source: "commercial",
    tags: {
      items: [
        { tagID: { name: "CSS", label: "CSS" } },
        { tagID: { name: "jQuery", label: "jQuery" } },
      ],
    },
  },
  {
    id: "au-senior-2019",
    name: "Australian Seniors Insurance",
    blurb: "Developed reusable .Net components with Kentico Cloud",
    description:
      "Developed reusable .Net components with Kentico Cloud, styled",
    url: "www.seniors.com.au/",
    year: "2019",
    source: "commercial",
    tags: {
      items: [
        { tagID: { name: "jQuery", label: "jQuery" } },
        { tagID: { name: "CSS", label: "CSS" } },
        { tagID: { name: "LESS", label: "LESS" } },
        { tagID: { name: ".Net", label: ".Net" } },
      ],
    },
  },
  {
    id: "akuhn-2019",
    name: "Aga Khan Nairobi | 60 year celebrations",
    blurb: "60 year celebrations for the Aga Khan Nairobi Hospital",
    description:
      "A registration page for 60 year celebrations of Aga Khan Hospital Nairobi; a public-facing user form",
    url:
      "https://hospitals.aku.edu/nairobi/AboutUs/Pages/60-year-celebrations.aspx",
    year: "2019",
    source: "commercial",
    tags: {
      items: [
        { tagID: { name: "angularJS", label: "angularJS" } },
        { tagID: { name: "sharepoint 2013", label: "sp 2013" } },
      ],
    },
  },
  {
    id: "intranet-it-requests-2019",
    name: "IT new requests dashboard",
    blurb: "A dashboard for all IT requests on the corporate portal",
    description:
      "A central dashboard with graphs for all IT requests which originate anywhere within the organization; A single point of update and status for all such reqeusts",
    url: "betaportal.aku.edu/IT/Dashboard/Pages/default.aspx",
    year: "2019",
    source: "commercial-intranet",
    tags: {
      items: [
        { tagID: { name: "react", label: "react" } },
        { tagID: { name: "sharepoint 2016", label: "sp 2016" } },
      ],
    },
  },
  {
    id: "intranet-attendance-2019",
    name: "Attendance module for staff",
    blurb:
      "Using employee cards to track attendance of staff for continual medical professional development hours",
    description:
      "Using employee cards to track attendance of employees, a rest-API hosted on NodeJS fetches the data from a propreitery database to mark time-in and time-out",
    url: "betaportal.aku.edu",
    year: "2019",
    source: "commercial-intranet",
    tags: {
      items: [
        { tagID: { name: "react", label: "react" } },
        { tagID: { name: "node", label: "node" } },
        { tagID: { name: "Bootstrap", label: "Bootstrap" } },
      ],
    },
  },
  {
    id: "akueb-2019",
    name: "AKU Examination Board | Stories",
    blurb:
      "A SPA to highlight the stories related to the AKU Examination Board",
    description:
      "Stories app to list stories, with free text field based search, filter by year; Dynamic detail pages with permutation/combinations of images/videos/pull quotes etc",
    url: "examinationboard.aku.edu/press-center/Pages/stories.aspx",
    year: "2019",
    source: "commercial",
    tags: {
      items: [
        { tagID: { name: "angularJS", label: "angularJS" } },
        { tagID: { name: "sharepoint 2013", label: "sp 2013" } },
      ],
    },
  },
  {
    id: "angular-ssr-2019",
    name: "Akber Iqbal | Angular server-side rendering",
    blurb:
      "Experimental page with server-side rendering on Angular using serverless & content same as akberiqbal.com homepage",
    description:
      "Server-side rendering powered by Angular Universal serves the page - super fast first load whilst still maintaining the usability of a single page application. Hosted with AWS Lambda.",
    url: "universal.akberiqbal.com",
    year: "2018",
    source: "personal",
    tags: {
      items: [
        { tagID: { name: "angular", label: "angular" } },
        { tagID: { name: "serverless", label: "serverless" } },
      ],
    },
  },
  {
    id: "akueb-videos-2019",
    name: "AKU Examination Board | Videos",
    blurb:
      "Dynamic sections and sequencing for official Examination Board videos on YouTube",
    description:
      "Dynamic sections and sequencing for official Examination Board videos on YouTube",
    url: "examinationboard.aku.edu/press-center/Pages/videos.aspx",
    year: "2018",
    source: "commercial",
    tags: {
      items: [
        { tagID: { name: "angularJS", label: "angularJS" } },
        { tagID: { name: "sharepoint 2013", label: "sp 2013" } },
      ],
    },
  },
  {
    id: "aku-news-2019",
    name: "News | The Aga Khan University",
    blurb:
      "AngularJS powered landing page with lazy loading, fast search on a high traffic page",
    description:
      "First major AngularJS based app on AKU's home website, fastest search & user experience unlike anything else on the website",
    url: "www.aku.edu/news/Pages/home.aspx",
    year: "2018",
    source: "commercial",
    tags: {
      items: [
        { tagID: { name: "angularJS", label: "angularJS" } },
        { tagID: { name: "sharepoint 2013", label: "sp 2013" } },
      ],
    },
  },
  {
    id: "akuhn-find-doctor-2019",
    name: "AKU Hospital Nairobi | Find a doctor",
    blurb:
      "Single page application for a high visibility and key application for the Aga Khan hospital in Nairobi, Kenya",
    description:
      "First ever AngularJS based app on the Nairobi hospital site - this is a high usage critical functionality for the hospital especially during a time of growth",
    url:
      "https://hospitals.aku.edu/nairobi/ForPatientAndVisitors/Pages/FindADoctor.aspx",
    year: "2018",
    source: "commercial",
    tags: {
      items: [
        { tagID: { name: "angularJS", label: "angularJS" } },
        { tagID: { name: "sharepoint 2013", label: "sp 2013" } },
      ],
    },
  },
  {
    id: "gold-2018",
    name: "Gold Fund Performance",
    blurb:
      "Tracking trends in prices of International gold price and local mutual fund",
    description:
      "Using publicly available data to check trends, variance of global gold price & Meezan Gold fund - with better resiliency against incorrect and duplicate data",
    url: "www.akberiqbal.com/apps/gold/gold",
    year: "2018",
    source: "personal",
    tags: {
      items: [
        { tagID: { name: "angular", label: "angular" } },
        { tagID: { name: "serverless", label: "serverless" } },
      ],
    },
  },
  {
    id: "ai-skills-2018",
    name: "Akber Iqbal | Skills",
    blurb:
      "Angular 5 with routing to display and filter public-facing work experience over the years",
    description:
      "Using routing to enable a single destination for filtering my projects by skills using all major tools, technologies",
    url: "skills.akberiqbal.com",
    year: "2018",
    source: "personal",
    tags: {
      items: [
        { tagID: { name: "angular", label: "angular" } },
        { tagID: { name: "serverless", label: "serverless" } },
      ],
    },
  },
  {
    id: "akueb-doc-library-2018",
    name: "AKU Examination Board | Document Library contents filtered",
    blurb:
      "A key app for the student base to filter and get to the resource that they are looking for",
    description:
      "Dynamic, cascading filters on exams, subjects for senior secondary and higher secondary programmes, built with AngularJS",
    url:
      "https://examinationboard.aku.edu/learning-materials/Pages/learning-resources-for-SSC-and-HSSC.aspx",
    year: "2018",
    source: "commercial",
    tags: {
      items: [
        { tagID: { name: "angularJS", label: "angularJS" } },
        { tagID: { name: "sharepoint 2013", label: "sp 2013" } },
      ],
    },
  },
  {
    id: "akueb-2018",
    name: "AKU Examination Board",
    blurb:
      "Complete website with custom branding, custom reusable components and new single page applications",
    description:
      "Production website for the Aga Khan University Examination board which caters to secondary & higher secondary students from across various areas of Pakistan",
    url: "examinationboard.aku.edu",
    year: "2017",
    source: "commercial",
    tags: {
      items: [
        { tagID: { name: "jQuery", label: "jQuery" } },
        { tagID: { name: "Bootstrap", label: "Bootstrap" } },
        { tagID: { name: "sharepoint 2013", label: "sp 2013" } },
      ],
    },
  },
  {
    id: "akueb-school-2018",
    name: "AKU Examination Board | School profiles",
    blurb:
      "A tool to help parents & strudents find a partner school in various parts of the country",
    description:
      "Automated an app with angular-js (first ever in my organization) with auto-complete & google-maps to help parents find a school near them",
    url: "examinationboard.aku.edu/services/Pages/school-profiles.aspx",
    year: "2017",
    source: "commercial",
    tags: {
      items: [
        { tagID: { name: "angularJS", label: "angularJS" } },
        { tagID: { name: "sharepoint 2013", label: "sp 2013" } },
      ],
    },
  },
  {
    id: "akueb-newsletter-2018",
    name: "AKU Examination Board | Newsletter subscription",
    blurb: "Simple interface to (un)subscribe for newsletter",
    description: "Developed the app to un/subscribe to Newsletter",
    url:
      "https://examinationboard.aku.edu/press-center/news/Pages/subscribe.aspx",
    year: "2017",
    source: "commercial",
    tags: {
      items: [
        { tagID: { name: "angularJS", label: "angularJS" } },
        { tagID: { name: "sharepoint 2013", label: "sp 2013" } },
      ],
    },
  },
  {
    id: "ai-home-2018",
    name: "Akber Iqbal | homepage",
    blurb:
      "Highly optimized progressive web app built on Angular and deployed on AWS serverless",
    description:
      "Progressive web app, optimized website built with Angular5, NodeJs powered by amazon web services lambda; scores of 95+ on Google PageSpeed and 490/500 on Google  lighthouse",
    url: "www.akberiqbal.com",
    year: "2017",
    source: "personal",
    tags: {
      items: [
        { tagID: { name: "angular", label: "angular" } },
        { tagID: { name: "serverless", label: "serverless" } },
      ],
    },
  },
  {
    id: "aku-main-2018",
    name: "Aga Khan University website",
    blurb:
      "Complete website with custom branding, custom reusable components and new single page applications",
    description:
      "Production website for the top teaching hospital and medical college of Pakistan: the Aga Khan University; With an agency developed custom design & reusable components and single page applications with a content team of 100+ spread across 5 countries in 3 continents",
    url: "www.akberiqbal.com",
    year: "2016",
    source: "commercial",
    tags: {
      items: [
        { tagID: { name: "jQuery", label: "jQuery" } },
        { tagID: { name: "Bootstrap", label: "Bootstrap" } },
        { tagID: { name: "sharepoint 2013", label: "sp2013" } },
      ],
    },
  },
  {
    id: "weather-forecast-2018",
    name: "Weather forecast",
    blurb:
      "Australian weather forecase app using react and chart.js and basic unit conversion",
    description:
      "Weather forecast app using react and chart.js, basic unit conversion, powered by an API and using unit, integration testing",
    year: "2020",
    url: "github.com/AkberIqbal/rehtaew",
    source: "open-source",
    tags: {
      items: [
        { tagID: { name: "react", label: "react" } },
        { tagID: { name: "charts", label: "charts" } },
        {
          tagID: {
            name: "react testing library",
            label: "react-testing-library",
          },
        },
        { tagID: { name: "cypress", label: "cypress" } },
      ],
    },
  },
  {
    id: "tennis-scorer-2018",
    name: "Tennis scorer",
    blurb: "Tennis scoring software including calculation for tie-breaks",
    description:
      "Tennis scoring software including calculation for tie-breaks, including unit, integration testing",
    year: "2020",
    url: "github.com/AkberIqbal/tennis-suid",
    source: "open-source",
    tags: {
      items: [
        { tagID: { name: "react", label: "react" } },
        {
          tagID: {
            name: "react testing library",
            label: "react-testing-library",
          },
        },
        { tagID: { name: "cypress", label: "cypress" } },
      ],
    },
  },
];
