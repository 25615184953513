import React from "react";
import "./App.css";
import { /* withAuthenticator, */ AmplifySignOut } from "@aws-amplify/ui-react";
import ApiSection from "./utils/api";
import DataEntryForm from "./components/portfolioForm";
import ListingComponent from "./components/listing";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ItemDetail from "./components/detail";
import Admin from "./components/admin";
import { ProtectedRoute } from "./core/protectedRoute";
import NotFound from "./components/notFound";

export const Home = () => {
  return (
    <div className="App">
      <ApiSection />
      <ListingComponent />
    </div>
  );
};

function App() {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/">
                <i
                  className="fa fa-home"
                  aria-hidden="true"
                  role="link"
                  aria-label="Home"
                ></i>
              </Link>
            </li>
            <li>
              <Link to="/admin">
                <i
                  className="fa fa-user-circle-o"
                  aria-hidden="true"
                  role="link"
                  aria-label="Admin"
                ></i>
              </Link>
            </li>
          </ul>
        </nav>

        <Switch>
          <Route exact path="*/detail/:id" component={ItemDetail} />
          <ProtectedRoute exact path="*/add" component={DataEntryForm} />
          <Route exact path="/" component={Home} />
          <Route exact path="*/admin" component={Admin} />
          <Route
            path="*"
            component={() => <NotFound passedString={"page"} />}
          />
        </Switch>
      </div>
    </Router>
  );
}

export default App; // withAuthenticator(App);
