import React from "react";
import { API } from "aws-amplify";
import {
  listPortfolioItems,
  getPortfolioItem,
  listTechnologyTags,
  listPortfolioTagConnections,
} from "../graphql/queries";
import {
  createPortfolioItem as createPortfolioItemMutation,
  updatePortfolioItem as updatePortfolioItemMutation,
  createTechnologyTag as createTechnologyTagMutation,
  createPortfolioTagConnection as createPortfolioTagConnectionMutation,
  deletePortfolioItem as deletePortfolioItemMutation,
} from "../graphql/mutations";

export const FetchNotesFromAPI = async () => {
  return API.graphql({ query: listPortfolioItems });
};

export const FetchIndividualPortfolioDetailFromAPI = async (passedID) => {
  return API.graphql({
    query: getPortfolioItem,
    variables: { id: passedID },
  });
};

export const FetchTagsFromAPI = async () => {
  return API.graphql({ query: listTechnologyTags });
};

export const FetchConnectionsFromAPI = async () => {
  return API.graphql({ query: listPortfolioTagConnections });
};

export const CreateNoteInAPI = async (formData) => {
  return API.graphql({
    query: createPortfolioItemMutation,
    variables: { input: formData },
  });
};
export const UpdateNoteInAPI = async (formData) => {
  return API.graphql({
    query: updatePortfolioItemMutation,
    variables: { input: formData },
  });
};

export const updateDateFormat = (passedDate) => {
  const formattedDate = new Date(passedDate);
  return formattedDate.toLocaleDateString();
};

export const CreateTagInAPI = async (formData) => {
  return API.graphql({
    query: createTechnologyTagMutation,
    variables: { input: formData },
  });
};

export const CreateConnectionInAPI = async (formData) => {
  return API.graphql({
    query: createPortfolioTagConnectionMutation,
    variables: { input: formData },
  });
};

export const DeleteNoteInAPI = async (id) => {
  return API.graphql({
    query: deletePortfolioItemMutation,
    variables: { input: { id } },
  });
};

export const FetchPortfolioItemsForTag = async (passedTagLabel) => {
  return API.graphql({
    query: listTechnologyTags,
    variables: { filter: { label: { eq: passedTagLabel } } },
  });
};

const ApiSection = () => {
  return (
    <>
      {/*       <h1>Bismillah ir Rehmaan ir Raheem</h1> */}
      <h1>Akber Iqbal</h1>
      <h2>consolidated portfolio</h2>
      <p>Made with React, GraphQL powered by AWS Amplify (serverless)</p>
    </>
  );
};

export default ApiSection;
