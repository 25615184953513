import React from "react";
import {
  FetchNotesFromAPI,
  // FetchTagsFromAPI,
  // FetchConnectionsFromAPI,
} from "../utils/api";
import { LOAD_AGAIN, LOAD_COMPLETE } from "../core/actions";
import { mockNotes } from "../data/mockData";

// Take in a component as argument WrappedComponent
export function GraphqlHOC(WrappedComponent) {
  const fetchNotes = async () => {
    return await FetchNotesFromAPI();
    // setNotes(apiData.data.listPortfolioItems.items);
    // updateNotes(apiData.data.listPortfolioItems.items);
  };

  /*   const fetchTags = async () => {
    return await FetchTagsFromAPI();
  };

  const fetchConnections = async () => {
    return await FetchConnectionsFromAPI();
  }; */

  // And return a new anonymous component
  return class extends React.Component {
    constructor(props) {
      super(props);
      // console.log("inside HOC with props:", props);
      this.state = {
        dataState:
          props &&
          props.portfolioItemsFromRedux &&
          props.portfolioItemsFromRedux.dataState
            ? props.portfolioItemsFromRedux.dataState
            : LOAD_AGAIN,
        apiData:
          props &&
          props.portfolioItemsFromRedux &&
          props.portfolioItemsFromRedux.itemsList
            ? props.portfolioItemsFromRedux.itemsList
            : [],
        tagsData: [],
        connData: [],
        apiError: [],
      };
    }

    componentDidMount() {
      if (
        this.state.apiData &&
        this.state.apiData.length >
          0 /* &&
        this.state.dataState === LOAD_COMPLETE */
      ) {
        // console.log(
        //   "we got state:",
        //   this.state.dataState,
        //   " - data:",
        //   this.state.apiData
        // );
      } else {
        fetchNotes()
          .then((response) => {
            console.log(
              "fetchNotes with data:",
              response.data.listPortfolioItems.items.length
            );
            this.setState({
              apiData: response.data.listPortfolioItems.items,
              dataState: LOAD_COMPLETE,
            });
            // setStoreData(
            //   JSON.stringify(response.data.listPortfolioItems.items)
            // );
            this.props.initializeTheList(
              response.data.listPortfolioItems.items
            );
          })
          .catch((err) => {
            console.log("fetchNotes with err:", err, " will save:", mockNotes);
            this.setState({
              apiError: err,
              apiData: mockNotes,
              dataState: LOAD_COMPLETE,
            });
            this.props.initializeTheList(mockNotes);
          });

        /*         fetchConnections()
          .then((resp) => {
            console.log("fetchConnections:", resp);
            this.setState({
              connData: resp.data.listPortfolioTagConnections.items,
            });
          })
          .catch((err) => {
            console.log("fetchConnections with err:", err);
            this.setState({ apiError: err });
          });

        fetchTags()
          .then((resp) => {
            console.log("fetchTags:", resp);
            this.setState({
              tagsData: resp.data.listTechnologyTags.items,
            });
          })
          .catch((err) => {
            console.log("fetchTags with err:", err);
            this.setState({ apiError: err });
          }); */
      }
    }

    render() {
      const newProps = { ...this.props, apiVars: this.state };
      return <WrappedComponent {...newProps} />;
    }
  };
}
