import React, { useState, useEffect } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
// import { initializeList, addToList } from "../core/actions";
import { FetchIndividualPortfolioDetailFromAPI } from "../utils/api";
import "../styles/detail.css";
import Auth from "@aws-amplify/auth";
import ItemUpdate from "./updatePortfolioItem";
import { updateDateFormat } from "../utils/api";
import TagsComponent from "./tags";
import NotFound from "./notFound";
import { mockNotes } from "../data/mockData";

const ItemDetail = (props) => {
  const match = useRouteMatch();
  const [individualItem, setIndividualItem] = useState({});
  const [username, setUsername] = useState("");
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [tagForDetail, setTagForDetail] = useState("");
  const [passedId, setPassedId] = useState(0);

  const getDetails = (passedID) => {
    FetchIndividualPortfolioDetailFromAPI(passedID)
      .then((resp) => {
        setIndividualItem(resp.data.getPortfolioItem);
        // console.log("FetchIndividualPortfolioDetailFromAPI:", resp);
        const firstTag =
          resp.data.getPortfolioItem.tags &&
          resp.data.getPortfolioItem.tags.items &&
          resp.data.getPortfolioItem.tags.items.length > 0
            ? resp.data.getPortfolioItem.tags.items[0].tagID
            : "";
        // console.log("we got firstTag:", firstTag);
        setTagForDetail(firstTag);
      })
      .catch((err) => {
        const foundIndividualFromMock = mockNotes.find(
          (x) => x.id === passedID
        );
        // console.log(
        //   "FetchIndividualPortfolioDetailFromAPI Error:",
        //   err,
        //   " but foundIndividualFromMock:",
        //   foundIndividualFromMock
        // );
        if (foundIndividualFromMock === undefined) {
          console.log("not found - we'll take your back");
          setIndividualItem("no-id");
        } else {
          if (foundIndividualFromMock) {
            setIndividualItem(foundIndividualFromMock);
            const firstTag =
              foundIndividualFromMock.tags &&
              foundIndividualFromMock.tags.items &&
              foundIndividualFromMock.tags.items.length > 0
                ? foundIndividualFromMock.tags.items[0].tagID
                : "";
            // console.log(
            //   "FetchIndividualPortfolioDetailFromAPI Error:",
            //   err,
            //   " but foundIndividualFromMock:",
            //   foundIndividualFromMock,
            //   " -- firstTag:",
            //   firstTag
            // );
            setTagForDetail(firstTag);
          }
        }
      });
  };

  useEffect(() => {
    // console.log(
    //   "inside ItemDetail with match:",
    //   match.params,
    //   " props:",
    //   props
    // );
    /* setIndividualItem(
      props.portfolioItemsFromRedux.itemsList.find(
        (x) => x.id === match.params.id
      )
    ); */
    if (Auth && Auth.user && Auth.user.username) {
      // console.log("auth useEffect with Auth:", Auth);
      setUsername(Auth.user.username);
    }
    getDetails(match.params.id);
    setPassedId(match.params.id);
  }, [match.params.id]);

  const updateRow = () => {
    // console.log("updateRow reqeust for individualItem:", individualItem);
    setShowUpdateRow(true);
  };

  const showThisTag = (nowUseThisTag) => {
    // console.log("showThisTag:", nowUseThisTag);
    setTagForDetail(nowUseThisTag);
  };

  if (individualItem === "no-id") return <Redirect to="/" />;
  return (
    <>
      {individualItem && individualItem.name !== "" ? (
        <>
          <div className="portfolioListDetail">
            <div>
              <h1 className="heading alignLeft">{individualItem.name}</h1>
              <span className="smallCase alignRightNow">
                {individualItem.year}
              </span>
            </div>
            <p className="portfolioListDetailContent">
              {individualItem.description}
            </p>
            {individualItem.tags &&
            individualItem.tags.items &&
            individualItem.tags.items.length > 0 ? (
              <ul className="detailTags">
                {individualItem.tags.items
                  .sort((a, b) => a.tagID.name.localeCompare(b.tagID.name))
                  .map((val, idx) => (
                    <div key={`ii${idx}`} id={`ii${idx}`}>
                      {val && val.tagID && val.tagID.name ? (
                        <li
                          key={`tag${idx}`}
                          id={`tag${idx}`}
                          onClick={() => {
                            showThisTag(val.tagID);
                          }}
                        >
                          {val.tagID.name}
                        </li>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
              </ul>
            ) : null}

            <div className="portfolioDetailSection">
              <div className="portfolioDetailLeft">
                <button
                  onClick={() => {
                    window.location.href = `http://${individualItem.url}`;
                  }}
                >
                  ...click here to visit...
                </button>
              </div>

              <div className="portfolioDetailRight">
                <span>{`type of work: ${individualItem.source}`}</span>
                <span>{`updated : ${updateDateFormat(
                  individualItem.updatedAt
                )}`}</span>
              </div>
            </div>
            {username && username !== "" ? (
              <button onClick={updateRow} type="button">
                Update this row
              </button>
            ) : null}
          </div>
          <div>
            {tagForDetail && tagForDetail !== "" ? (
              <TagsComponent tagForDetail={tagForDetail} passedId={passedId} />
            ) : null}
          </div>
        </>
      ) : (
        <NotFound passedString={"portfolio item detail"} />
      )}
      {showUpdateRow ? <ItemUpdate passedItem={individualItem} /> : null}
    </>
  );
};

/* 
const mapStateToProps = (state) => ({
  portfolioItemsFromRedux: state, // getVisibleTodos(state.todos, state.visibilityFilter),
});

const mapDispatchToProps = (dispatch) => ({
  // toggleTodo: (id) => dispatch(toggleTodo(id)),
  initializeTheList: (passedArray) => dispatch(initializeList(passedArray)),
  callAddToList: (passedObject) => dispatch(addToList(passedObject)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GraphqlHOC(ItemDetail)); */

export default ItemDetail;
