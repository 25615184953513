import React, { useState } from "react";
import { connect } from "react-redux";
import { CreateTagInAPI } from "../utils/api";
import "../styles/components.css";
import { setLoadAgainState } from "../core/actions";

const initialFormState = {
  label: "",
  name: "",
};

const TagForm = ({ setLoadAgainStateNow }) => {
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState(initialFormState);

  const changeHandler = (e, fieldName) => {
    let updatedForm = { ...formData };
    updatedForm[fieldName] = e.target.value;
    setFormData(updatedForm);
  };

  const createATag = async () => {
    if (!formData.name) return;
    CreateTagInAPI(formData)
      .then((createResponse) => {
        setFormData(initialFormState);
        setLoadAgainStateNow();
      })
      .catch((err) => {
        // console.log("then with err:", err);
        let createErrors = [...errors];
        err.errors.forEach((individualError) => {
          createErrors = [...createErrors, individualError.message];
        });
        setErrors(createErrors);
      });
  };

  return (
    <>
      <h1>Add Tags here</h1>
      <label htmlFor="tItemLabel">Tag Label</label>
      <input
        id="tItemLabel"
        onChange={(e) => changeHandler(e, "label")}
        placeholder="tag label"
        value={formData.label}
      />
      <br />
      <label htmlFor="tItemName">Tag Name</label>
      <input
        id="tItemName"
        onChange={(e) => changeHandler(e, "name")}
        placeholder="name"
        value={formData.name}
      />
      <br />
      {errors && errors.length > 0 ? (
        <ul>
          {errors.map((err, indx) => (
            <li key={`li${indx}`} id={`li${indx}`} className="errorMessage">
              {err}
            </li>
          ))}
        </ul>
      ) : null}
      <button onClick={createATag}>Create Tag</button>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLoadAgainStateNow: () => dispatch(setLoadAgainState()),
});

export default connect(null, mapDispatchToProps)(TagForm);
