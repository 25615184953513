/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:96a46952-ce8d-4645-8b48-78428e2fd9a1",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_qxkGbsK0c",
    "aws_user_pools_web_client_id": "636m194rlr0746gsjllnnqiqs5",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://mibx7uhskjeyhd6mcu5w4whtjy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-c7k74vx7ires5pj4cpi4bdt5ei"
};


export default awsmobile;
