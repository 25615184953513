import React, { useState, useEffect } from "react";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
// import ApiSection from "../utils/api";
// import ListingComponent from "./listing";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ItemDetail from "./detail";
// import ReduxTesting from "./reduxTesting";
import Auth from "@aws-amplify/auth";
import { Home } from "../App";
import "../styles/components.css";
import { ProtectedRoute } from "../core/protectedRoute";
import DataEntryForm from "./portfolioForm";
import TagsForm from "./tagsForm";
import ConnectionsForm from "./connectionsForm";

function Admin() {
  const [username, setUsername] = useState("");

  useEffect(() => {
    setUsername(Auth.user.user);
    // console.log("auth useEffect with Auth:", Auth);
  }, []);

  return (
    <>
      <h1>Admin Area</h1>
      <p>Hello {username || ""}</p>
      <Router>
        <div>
          <nav>
            <ul>
              <li>
                <Link to="/">list</Link>
              </li>
              <li>
                <Link to="/add">Add a portfolio item</Link>
              </li>
              <li>
                <Link to="/addTag">Add a tag</Link>
              </li>
              <li>
                <Link to="/addConn">Add a Conn</Link>
              </li>
            </ul>
          </nav>

          <Switch>
            <ProtectedRoute exact path="*/add" component={DataEntryForm} />
            <ProtectedRoute exact path="*/addTag" component={TagsForm} />
            <ProtectedRoute
              exact
              path="*/addConn"
              component={ConnectionsForm}
            />
            <Route exact path="*/detail/:id" component={ItemDetail} />
            <Route exact path="/" component={Home} />
          </Switch>
        </div>
      </Router>
      <AmplifySignOut />
    </>
  );
}

export default withAuthenticator(Admin);
