import React from "react";

const NotFound = ({ passedString }) => {
  return (
    <>
      <h1>Oops! No {passedString} was found... </h1>
      <p>
        Are you sure that you entered a correct URL? click <a href="/">here</a>{" "}
        to go to the main page
      </p>
    </>
  );
};

export default NotFound;
