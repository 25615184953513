import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { UpdateNoteInAPI } from "../utils/api";
import "../styles/components.css";
import Auth from "@aws-amplify/auth";

const initialFormState = {
  name: "",
  blurb: "",
  description: "",
  url: "",
  year: "",
  source: "",
};

const ItemUpdate = ({ passedItem }) => {
  const [individualItem, setIndividualItem] = useState(initialFormState);
  const [username, setUsername] = useState("");
  const [updateErrors, setUpdateErrors] = useState([]);

  useEffect(() => {
    // console.log("passedItem:", passedItem);
    const { tags, createdAt, updatedAt, ...everythingExceptTags } = passedItem;
    setIndividualItem(everythingExceptTags);

    if (Auth && Auth.user && Auth.user.username) {
      // console.log("auth useEffect with Auth:", Auth);
      setUsername(Auth.user.username);
    }
  }, [passedItem]);

  const updateRowNow = () => {
    // console.log("updateRow reqeust for individualItem:", individualItem);
    UpdateNoteInAPI(individualItem)
      .then((resp) => {
        // console.log("UpdateNoteInAPI resp:", resp);
        setIndividualItem(initialFormState);
      })
      .catch((err) => {
        // console.log("UpdateNoteInAPI err:", err);
        setUpdateErrors(err.errors);
      });
  };

  const changeHandler = (e, fieldName) => {
    let updatedForm = { ...individualItem };
    updatedForm[fieldName] =
      fieldName === "year" ? parseInt(e.target.value) : e.target.value;
    setIndividualItem(updatedForm);
  };

  if (individualItem === "no-id") return <Redirect to="/" />;
  return (
    <>
      <h1>UPDATE PAGE</h1>
      {individualItem && individualItem.name !== "" ? (
        <div className="portfolioListDetail">
          <label htmlFor="iName">Name</label>
          <input
            id="iName"
            type="text"
            value={individualItem.name || ""}
            onChange={(e) => changeHandler(e, "name")}
          />
          <br />
          <label htmlFor="iBlurb">blurb</label>
          <input
            id="iBlurb"
            type="text"
            value={individualItem.blurb || ""}
            onChange={(e) => changeHandler(e, "blurb")}
          />
          <br />
          <label htmlFor="iDescription">Description</label>
          <input
            id="iDescription"
            type="text"
            value={individualItem.description || ""}
            onChange={(e) => changeHandler(e, "description")}
          />
          <br />
          <label htmlFor="iUrl">url</label>
          <input
            id="iUrl"
            type="text"
            value={individualItem.url || ""}
            onChange={(e) => changeHandler(e, "url")}
          />
          <br />
          <label htmlFor="iYear">year</label>
          <input
            id="iYear"
            type="number"
            value={individualItem.year || 0}
            onChange={(e) => changeHandler(e, "year")}
          />
          <br />
          <label htmlFor="iSource">source</label>
          <input
            id="iSource"
            type="text"
            value={individualItem.source || ""}
            onChange={(e) => changeHandler(e, "source")}
          />
          <br />

          {username && username !== "" ? (
            <button onClick={updateRowNow} type="button">
              Update this row now
            </button>
          ) : (
            <p>youre not signed in</p>
          )}

          {updateErrors && updateErrors.length > 0 ? (
            <>
              {updateErrors.map((err, val) => (
                <p> {err.message}</p>
              ))}
            </>
          ) : null}
        </div>
      ) : (
        <p>no details found</p>
      )}
    </>
  );
};

export default ItemUpdate;
