export const mockTags = [
  {
    parentItem: {
      items: [
        {
          portfolioID: {
            id: "weatherzone",
            name: "nextJS",
            label: "nextJS",
            blurb: "Weatherzone's public site on NextJS, storybook",
          },
        },
        {
          portfolioID: {
            id: "weatherzone",
            name: "storybook",
            label: "storybook",
            blurb: "Weatherzone's public site on NextJS, storybook",
          },
        },
        {
          portfolioID: {
            id: "weatherzone",
            name: "styled components",
            label: "styled-components",
            blurb: "Weatherzone's public site on NextJS, storybook",
          },
        },

        {
          portfolioID: {
            id: "Westpac Open banking",
            name: "react",
            label: "react",
            blurb:
              "Developed back-end and front-end endpoints for westpac's open banking project",
          },
        },
        {
          portfolioID: {
            id: "Westpac Open banking",
            name: "redux",
            label: "redux",
            blurb:
              "Developed back-end and front-end endpoints for westpac's open banking project",
          },
        },
        {
          portfolioID: {
            id: "Westpac Open banking",
            name: "jest",
            label: "jest",
            blurb:
              "Developed back-end and front-end endpoints for westpac's open banking project",
          },
        },
        {
          portfolioID: {
            id: "Westpac Open banking",
            name: "styled components",
            label: "styled-components",
            blurb:
              "Developed back-end and front-end endpoints for westpac's open banking project",
          },
        },

        {
          portfolioID: {
            id: "QBE Motor/Home Insurance",
            name: "react",
            label: "react",
            blurb:
              "Key application for the personal line of business for QBE with strong reliance on API for questions, get quote as a response",
          },
        },
        {
          portfolioID: {
            id: "QBE Motor/Home Insurance",
            name: "redux",
            label: "redux",
            blurb:
              "Key application for the personal line of business for QBE with strong reliance on API for questions, get quote as a response",
          },
        },
        {
          portfolioID: {
            id: "QBE Motor/Home Insurance",
            name: "Material UI",
            label: "MaterialUI",
            blurb:
              "Key application for the personal line of business for QBE with strong reliance on API for questions, get quote as a response",
          },
        },
        {
          portfolioID: {
            id: "QBE Motor/Home Insurance",
            name: "styled components",
            label: "styled-components",
            blurb:
              "Key application for the personal line of business for QBE with strong reliance on API for questions, get quote as a response",
          },
        },
        {
          portfolioID: {
            id: "QBE Motor/Home Insurance",
            name: "redux saga",
            label: "redux-saga",
            blurb:
              "Key application for the personal line of business for QBE with strong reliance on API for questions, get quote as a response",
          },
        },
        {
          portfolioID: {
            id: "Akber Iqbal | Career",
            name: "react",
            label: "react",
            blurb:
              "Redux, routing used to track a user journey across the pages which highlight a different area of my profile",
          },
        },
        {
          portfolioID: {
            id: "Akber Iqbal | Career",
            name: "redux",
            label: "redux",
            blurb:
              "Redux, routing used to track a user journey across the pages which highlight a different area of my profile",
          },
        },
        {
          portfolioID: {
            id: "Akber Iqbal | Career",
            name: "Bootstrap 4",
            label: "Bootstrap 4",
            blurb:
              "Redux, routing used to track a user journey across the pages which highlight a different area of my profile",
          },
        },
        {
          portfolioID: {
            id: "Choosi",
            name: "jQuery",
            label: "jQuery",
            blurb:
              "Some reusable components on the banner, SEO changes implemented",
          },
        },
        {
          portfolioID: {
            id: "Choosi",
            name: "CSS",
            label: "CSS",
            blurb:
              "Some reusable components on the banner, SEO changes implemented",
          },
        },
        {
          portfolioID: {
            id: "RSPCA Insurance",
            name: "jQuery",
            label: "jQuery",
            blurb: "Developed custom layouts for new landing and detail pages",
          },
        },
        {
          portfolioID: {
            id: "RSPCA Insurance",
            name: "CSS",
            label: "CSS",
            blurb: "Developed custom layouts for new landing and detail pages",
          },
        },
        {
          portfolioID: {
            id: "Australian Seniors Insurance",
            name: "jQuery",
            label: "jQuery",
            blurb: "Developed reusable .Net components with Kentico Cloud",
          },
        },
        {
          portfolioID: {
            id: "Australian Seniors Insurance",
            name: "CSS",
            label: "CSS",
            blurb: "Developed reusable .Net components with Kentico Cloud",
          },
        },
        {
          portfolioID: {
            id: "Australian Seniors Insurance",
            name: "LESS",
            label: "LESS",
            blurb: "Developed reusable .Net components with Kentico Cloud",
          },
        },
        {
          portfolioID: {
            id: "Australian Seniors Insurance",
            name: ".Net",
            label: ".Net",
            blurb: "Developed reusable .Net components with Kentico Cloud",
          },
        },

        {
          portfolioID: {
            id: "Aga Khan Nairobi | 60 year celebrations",
            name: "angularJS",
            label: "angularJS",
            blurb: "60 year celebrations for the Aga Khan Nairobi Hospital",
          },
        },
        {
          portfolioID: {
            id: "Aga Khan Nairobi | 60 year celebrations",
            name: "sharepoint 2016",
            label: "sp2016",
            blurb: "60 year celebrations for the Aga Khan Nairobi Hospital",
          },
        },
        {
          portfolioID: {
            id: "IT new requests dashboard",
            name: "react",
            label: "react",
            blurb: "A dashboard for all IT requests on the corporate portal",
          },
        },
        {
          portfolioID: {
            id: "IT new requests dashboard",
            name: "sharepoint 2016",
            label: "sp2016",
            blurb: "A dashboard for all IT requests on the corporate portal",
          },
        },

        {
          portfolioID: {
            id: "Attendance module for staff",
            name: "react",
            label: "react",
            blurb:
              "Using employee cards to track attendance of staff for continual medical professional development hours",
          },
        },
        {
          portfolioID: {
            id: "Attendance module for staff",
            name: "node",
            label: "node",
            blurb:
              "Using employee cards to track attendance of staff for continual medical professional development hours",
          },
        },
        {
          portfolioID: {
            id: "Attendance module for staff",
            name: "Bootstrap",
            label: "Bootstrap",
            blurb:
              "Using employee cards to track attendance of staff for continual medical professional development hours",
          },
        },
        {
          portfolioID: {
            id: "AKU Examination Board | Stories",
            name: "angularJS",
            label: "angularJS",
            blurb:
              "A SPA to highlight the stories related to the AKU Examination Board",
          },
        },
        {
          portfolioID: {
            id: "AKU Examination Board | Stories",
            name: "sharepoint 2013",
            label: "sp 2013",
            blurb:
              "A SPA to highlight the stories related to the AKU Examination Board",
          },
        },
        {
          portfolioID: {
            id: "Akber Iqbal | Angular server-side rendering",
            name: "angular",
            label: "angular",
            blurb:
              "Experimental page with server-side rendering on Angular using serverless & content same as akberiqbal.com homepage",
          },
        },
        {
          portfolioID: {
            id: "Akber Iqbal | Angular server-side rendering",
            name: "serverless",
            label: "serverless",
            blurb:
              "Experimental page with server-side rendering on Angular using serverless & content same as akberiqbal.com homepage",
          },
        },
        {
          portfolioID: {
            id: "AKU Examination Board | Videos",
            name: "angularJS",
            label: "angularJS",
            blurb:
              "Dynamic sections and sequencing for official Examination Board videos on YouTube",
          },
        },
        {
          portfolioID: {
            id: "AKU Examination Board | Videos",
            name: "sharepoint 2013",
            label: "sp 2013",
            blurb:
              "Dynamic sections and sequencing for official Examination Board videos on YouTube",
          },
        },
        {
          portfolioID: {
            id: "News | The Aga Khan University",
            name: "angularJS",
            label: "angularJS",
            blurb:
              "AngularJS powered landing page with lazy loading, fast search on a high traffic page",
          },
        },
        {
          portfolioID: {
            id: "News | The Aga Khan University",
            name: "sharepoint 2013",
            label: "sp 2013",
            blurb:
              "AngularJS powered landing page with lazy loading, fast search on a high traffic page",
          },
        },
        {
          portfolioID: {
            id: "AKU Hospital Nairobi | Find a doctor",
            name: "angularJS",
            label: "angularJS",
            blurb:
              "Single page application for a high visibility and key application for the Aga Khan hospital in Nairobi, Kenya",
          },
        },
        {
          portfolioID: {
            id: "AKU Hospital Nairobi | Find a doctor",
            name: "sharepoint 2013",
            label: "sp 2013",
            blurb:
              "Single page application for a high visibility and key application for the Aga Khan hospital in Nairobi, Kenya",
          },
        },
        {
          portfolioID: {
            id: "Gold Fund Performance",
            name: "angular",
            label: "angular",
            blurb:
              "Tracking trends in prices of International gold price and local mutual fund",
          },
        },
        {
          portfolioID: {
            id: "Gold Fund Performance",
            name: "serverless",
            label: "serverless",
            blurb:
              "Tracking trends in prices of International gold price and local mutual fund",
          },
        },
        {
          portfolioID: {
            id: "Akber Iqbal | Skills",
            name: "angular",
            label: "angular",
            blurb:
              "Angular 5 with routing to display and filter public-facing work experience over the years",
          },
        },
        {
          portfolioID: {
            id: "Akber Iqbal | Skills",
            name: "serverless",
            label: "serverless",
            blurb:
              "Angular 5 with routing to display and filter public-facing work experience over the years",
          },
        },
        {
          portfolioID: {
            id: "AKU Examination Board | Document Library contents filtered",
            name: "angularJS",
            label: "angularJS",
            blurb:
              "A key app for the student base to filter and get to the resource that they are looking for",
          },
        },
        {
          portfolioID: {
            id: "AKU Examination Board | Document Library contents filtered",
            name: "sharepoint 2013",
            label: "sp 2013",
            blurb:
              "A key app for the student base to filter and get to the resource that they are looking for",
          },
        },
        {
          portfolioID: {
            id: "AKU Examination Board",
            name: "Bootstrap",
            label: "Bootstrap",
            blurb:
              "Complete website with custom branding, custom reusable components and new single page applications",
          },
        },
        {
          portfolioID: {
            id: "AKU Examination Board",
            name: "jQuery",
            label: "jQuery",
            blurb:
              "Complete website with custom branding, custom reusable components and new single page applications",
          },
        },
        {
          portfolioID: {
            id: "AKU Examination Board",
            name: "sharepoint 2013",
            label: "sp 2013",
            blurb:
              "Complete website with custom branding, custom reusable components and new single page applications",
          },
        },

        {
          portfolioID: {
            id: "AKU Examination Board | School profiles",
            name: "angularJS",
            label: "angularJS",
            blurb:
              "A tool to help parents & strudents find a partner school in various parts of the country",
          },
        },
        {
          portfolioID: {
            id: "AKU Examination Board | School profiles",
            name: "sharepoint 2013",
            label: "sp 2013",
            blurb:
              "A tool to help parents & strudents find a partner school in various parts of the country",
          },
        },
        {
          portfolioID: {
            id: "AKU Examination Board | Newsletter subscription",
            name: "angularJS",
            label: "angularJS",
            blurb: "Simple interface to (un)subscribe for newsletter",
          },
        },
        {
          portfolioID: {
            id: "AKU Examination Board | Newsletter subscription",
            name: "sharepoint 2013",
            label: "sp 2013",
            blurb: "Simple interface to (un)subscribe for newsletter",
          },
        },
        {
          portfolioID: {
            id: "Akber Iqbal | homepage",
            name: "angular",
            label: "angular",
            blurb:
              "Highly optimized progressive web app built on Angular and deployed on AWS serverless",
          },
        },
        {
          portfolioID: {
            id: "Akber Iqbal | homepage",
            name: "serverless",
            label: "serverless",
            blurb:
              "Highly optimized progressive web app built on Angular and deployed on AWS serverless",
          },
        },
        {
          portfolioID: {
            id: "Aga Khan University website",
            name: "Bootstrap",
            label: "Bootstrap",
            blurb:
              "Complete website with custom branding, custom reusable components and new single page applications",
          },
        },
        {
          portfolioID: {
            id: "Aga Khan University website",
            name: "sharepoint 2013",
            label: "sp2013",
            blurb:
              "Complete website with custom branding, custom reusable components and new single page applications",
          },
        },
        {
          portfolioID: {
            id: "Aga Khan University website",
            name: "jQuery",
            label: "jQuery",
            blurb:
              "Complete website with custom branding, custom reusable components and new single page applications",
          },
        },

        {
          portfolioID: {
            id: "Weather forecast",
            name: "charts",
            label: "charts",
            blurb:
              "Australian weather forecase app using react and chart.js and basic unit conversion",
          },
        },
        {
          portfolioID: {
            id: "Weather forecast",
            name: "react",
            label: "react",
            blurb:
              "Australian weather forecase app using react and chart.js and basic unit conversion",
          },
        },

        {
          portfolioID: {
            id: "Weather forecast",
            name: "cypress",
            label: "cypress",
            blurb:
              "Australian weather forecase app using react and chart.js and basic unit conversion",
          },
        },
        {
          portfolioID: {
            id: "Weather forecast",
            name: "react testing library",
            label: "react-testing-library",
            blurb:
              "Australian weather forecase app using react and chart.js and basic unit conversion",
          },
        },
        {
          portfolioID: {
            id: "Tennis scorer",
            name: "react",
            label: "react",
            blurb:
              "Tennis scoring software including calculation for tie-breaks",
          },
        },
        {
          portfolioID: {
            id: "Tennis scorer",
            name: "cypress",
            label: "cypress",
            blurb:
              "Tennis scoring software including calculation for tie-breaks",
          },
        },
        {
          portfolioID: {
            id: "Tennis scorer",
            name: "react testing library",
            label: "react-testing-library",
            blurb:
              "Tennis scoring software including calculation for tie-breaks",
          },
        },

        // {
        //   portfolioID: {
        //     id: "3a180e34-937b-4373-a19f-fe3ad2e75030",
        //     name: "react",
        //     label: "react",
        //     blurb: "react-blurb",
        //   },
        // },
        // {
        //   portfolioID: {
        //     id: "portfolio-item-2",
        //     name: "react2",
        //     label: "react",
        //     blurb: "react-blurb2",
        //   },
        // },
        // {
        //   portfolioID: {
        //     id: "portfolio-item-3",
        //     name: "react3",
        //     label: "react",
        //     blurb: "react-blurb3",
        //   },
        // },
        // {
        //   portfolioID: "some-other",
        //   name: "angular",
        //   label: "angular-label",
        //   blurb: "angular-blurb",
        // },
        // {
        //   portfolioID: "portfolio-item-2",
        //   name: "react",
        //   label: "react-label",
        //   blurb: "react-blurb",
        // },
      ],
    },
  },
];
