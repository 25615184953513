/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPortfolioItem = /* GraphQL */ `
  query GetPortfolioItem($id: ID!) {
    getPortfolioItem(id: $id) {
      id
      name
      blurb
      description
      url
      year
      source
      tags {
        items {
          id
          createdAt
          updatedAt
          tagID {
            id
            label
            name
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPortfolioItems = /* GraphQL */ `
  query ListPortfolioItems(
    $filter: ModelportfolioItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortfolioItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        blurb
        description
        url
        year
        source
        tags {
          items {
            tagID {
              label
              name
            }
          }
        }
      }
    }
  }
`;
export const getPortfolioTagConnection = /* GraphQL */ `
  query GetPortfolioTagConnection($id: ID!) {
    getPortfolioTagConnection(id: $id) {
      id
      portfolioID {
        id
        name
        blurb
        description
        url
        year
        source
        tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      tagID {
        id
        label
        name
        parentItem {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPortfolioTagConnections = /* GraphQL */ `
  query ListPortfolioTagConnections(
    $filter: ModelportfolioTagConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortfolioTagConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        portfolioID {
          id
          name
          blurb
          description
          url
          year
          source
          createdAt
          updatedAt
        }
        tagID {
          id
          label
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTechnologyTag = /* GraphQL */ `
  query GetTechnologyTag($id: ID!) {
    getTechnologyTag(id: $id) {
      id
      label
      name
      parentItem {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTechnologyTags = /* GraphQL */ `
  query ListTechnologyTags(
    $filter: ModelTechnologyTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTechnologyTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        name
        parentItem {
          items {
            id
            portfolioID {
              id
              name
              blurb
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
