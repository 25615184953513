import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  FetchNotesFromAPI,
  FetchTagsFromAPI,
  CreateConnectionInAPI,
  FetchConnectionsFromAPI,
} from "../utils/api";
import "../styles/components.css";
import { setLoadAgainState } from "../core/actions";

const initialFormState = {
  portfolioTagConnectionPortfolioIDId: "",
  portfolioTagConnectionTagIDId: "",
};

const ConnectionsForm = ({ setLoadAgainStateNow }) => {
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState(initialFormState);
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [portfolioErrors, setPortfolioErrors] = useState(null);
  const [techTags, setTechTags] = useState([]);
  const [techTagsErrors, setTechTagsErrors] = useState(null);
  const [existingConns, setExistingConns] = useState([]);
  // const [existingConnsErrors, setExistingConnsErrors] = useState(null);

  const fetchNotes = async () => {
    return await FetchNotesFromAPI();
  };

  const fetchTags = async () => {
    return await FetchTagsFromAPI();
  };

  const fetchConnections = async () => {
    return await FetchConnectionsFromAPI();
  };
  console.log(
    "portfolioErrors:",
    portfolioErrors,
    " -techTagsErrors:",
    techTagsErrors
  );

  useEffect(() => {
    fetchNotes()
      .then((resp) => {
        setPortfolioItems(resp.data.listPortfolioItems.items);
      })
      .catch((err) => {
        // console.log("fetchNotes with err:", err);
        setPortfolioErrors(err);
      });

    fetchTags()
      .then((resp) => {
        setTechTags(resp.data.listTechnologyTags.items);
      })
      .catch((err) => {
        setTechTagsErrors(err);
      });
    fetchConnections()
      .then((resp) => {
        setExistingConns(resp.data.listPortfolioTagConnections.items);
        // console.log("success:", resp.data.listPortfolioTagConnections.items);
      })
      .catch((err) => {
        setTechTagsErrors(err);
        console.log("fetchConnections err:", err);
      });
  }, []);

  const changeHandler = (e, fieldName) => {
    // console.log(
    //   "inside changeHandler for:",
    //   fieldName,
    //   " with e:",
    //   e.target.value
    // );
    let updatedForm = { ...formData };
    updatedForm[fieldName] = e.target.value;
    setFormData(updatedForm);
  };

  const createAConnection = async () => {
    // console.log("createAConnection button clicked with:", formData);
    if (
      !formData.portfolioTagConnectionPortfolioIDId ||
      !formData.portfolioTagConnectionTagIDId
    )
      return;
    CreateConnectionInAPI(formData)
      .then((createResponse) => {
        // console.log("CreateConnectionInAPI success:", createResponse);
        // setFormData(initialFormState);
        setLoadAgainStateNow();
      })
      .catch((err) => {
        // console.log("CreateConnectionInAPI with err:", err);
        let createErrors = [...errors];
        err.errors.forEach((individualError) => {
          createErrors = [...createErrors, individualError.message];
        });
        setErrors(createErrors);
      });
  };

  return (
    <>
      <h1>Add Connections here</h1>
      <label htmlFor="cPortfolioID">Portfolio ID</label>
      <input
        id="cPortfolioID"
        onChange={(e) =>
          changeHandler(e, "portfolioTagConnectionPortfolioIDId")
        }
        placeholder="portfolio ID"
        value={formData.portfolioTagConnectionPortfolioIDId}
        disabled
      />
      {portfolioItems && portfolioItems.length > 0 ? (
        <select
          onChange={(e) =>
            changeHandler(e, "portfolioTagConnectionPortfolioIDId")
          }
        >
          <option value="">please select </option>
          {portfolioItems
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((val, indx) => (
              <option
                id={`portfolio${indx}`}
                key={`portfolio${indx}`}
                value={val.id}
              >
                {val.name}
              </option>
            ))}
        </select>
      ) : null}

      <br />
      <label htmlFor="cTagID">Tag ID</label>
      <input
        id="cTagID"
        onChange={(e) => changeHandler(e, "portfolioTagConnectionTagIDId")}
        placeholder="tag ID"
        value={formData.portfolioTagConnectionTagIDId}
        disabled
      />
      {techTags && techTags.length > 0 ? (
        <select
          onChange={(e) => changeHandler(e, "portfolioTagConnectionTagIDId")}
        >
          <option value="">please select </option>
          {techTags
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((val, indx) => (
              <option id={`tag${indx}`} key={`tag${indx}`} value={val.id}>
                {val.name}
              </option>
            ))}
        </select>
      ) : null}
      <br />
      {errors && errors.length > 0 ? (
        <ul>
          {errors.map((err, indx) => (
            <li key={`li${indx}`} id={`li${indx}`} className="errorMessage">
              {err}
            </li>
          ))}
        </ul>
      ) : null}
      <button onClick={createAConnection}>Create a connection</button>
      <hr />
      {/*       <section>
        <h2>Existing Portfolio Items</h2>
        {portfolioItems && portfolioItems.length > 0 ? (
          <ul>
            {portfolioItems.map((val, indx) => (
              <li id={`portfolio${indx}`} key={`portfolio${indx}`}>
                {val.id} - {val.name} - {val.blurb}
              </li>
            ))}
          </ul>
        ) : null}
      </section>
      <section>
        <h2>Existing Tags</h2>
        {techTags && techTags.length > 0 ? (
          <ul>
            {techTags.map((val, indx) => (
              <li id={`tag${indx}`} key={`tag${indx}`}>
                {val.id} - {val.label} - {val.name}
              </li>
            ))}
          </ul>
        ) : null}
      </section> */}
      <section>
        <h2>Existing Connections</h2>
        {existingConns && existingConns.length > 0 ? (
          <ul>
            {existingConns
              .filter((x) => x.portfolioID !== null)
              .sort((a, b) =>
                a.portfolioID.name.localeCompare(b.portfolioID.name)
              )
              .map((val, indx) => (
                <li id={`tag${indx}`} key={`tag${indx}`}>
                  {val.portfolioID && val.portfolioID.name
                    ? val.portfolioID.name
                    : "N/A"}
                  - {val.tagID && val.tagID.label ? val.tagID.label : "N/A"}
                </li>
              ))}
          </ul>
        ) : null}
      </section>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLoadAgainStateNow: () => dispatch(setLoadAgainState()),
});

export default connect(null, mapDispatchToProps)(ConnectionsForm);
