import React, { useState, useEffect } from "react";
import { FetchPortfolioItemsForTag } from "../utils/api";
import "../styles/detail.css";
import { mockTags } from "../data/mockTags";

const TagsComponent = ({ tagForDetail, passedId }) => {
  const [passedTag, setPassedTag] = useState("");
  const [taggedItems, setTaggedItems] = useState([]);

  useEffect(() => {
    // console.log("inside tag component: ", tagForDetail);
    if (tagForDetail && tagForDetail.label) {
      setPassedTag(tagForDetail);
      FetchPortfolioItemsForTag(tagForDetail.label)
        .then((resp) => {
          // console.log("inside FetchPortfolioItemsForTag: ", resp);
          setTaggedItems(resp.data.listTechnologyTags.items);
        })
        .catch((err) => {
          // console.log(
          //   "TagsComponent failed with:",
          //   err,
          //   " but will save mockTags:",
          //   mockTags
          // );
          setTaggedItems(mockTags);
        });
    }
  }, [tagForDetail]);

  return (
    <div>
      {taggedItems &&
      taggedItems.length > 0 &&
      taggedItems[0].parentItem &&
      taggedItems[0].parentItem.items &&
      taggedItems[0].parentItem.items.length > 0 ? (
        <div className="tagComponent">
          <h2>
            Other portfolio items using<b>{`: ${passedTag.label}`}</b>
          </h2>
          <p>
            Click on the tags in the detail section above to refresh this list
            filtered upon that particular technology
          </p>
          <div className="tagContainer">
            {taggedItems[0].parentItem.items.map((pItem, idx) => {
              // console.log(
              //   "inside with pItem:",
              //   pItem.portfolioID.name,
              //   " vs.",
              //   passedId,
              //   " and passedTag.label:",
              //   passedTag.label
              // );
              return pItem.portfolioID.id !== passedId &&
                pItem.portfolioID.name === passedTag.label &&
                pItem.portfolioID &&
                pItem.portfolioID.name &&
                pItem.portfolioID.name ? (
                <div className="tagItems" key={`pItem${idx}`}>
                  <span className="pItemName">{pItem.portfolioID.id} </span>
                  <span className="pItemBlurb">{pItem.portfolioID.blurb} </span>
                  <a href={`/detail/${pItem.portfolioID.id}`}>...details</a>
                </div>
              ) : null;
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TagsComponent;
